import React, { useState, useEffect, useRef, useCallback } from "react";
import { Stage, Layer } from "react-konva";
import { useDropzone } from "react-dropzone";
import { HexColorPicker } from "react-colorful";
import { FaCopy } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import URLImage from "../URLImage/URLImage";
import DraggableText from "../DraggableText/DraggableText";
import styles from "./Dankify.module.css";

const Dankify = () => {
  // =================================
  // BACKGROUND STATE & Auto-Collapse
  // =================================
  const [activeTab, setActiveTab] = useState("stock"); // "color" | "stock" | "upload"
  const [bgCollapsed, setBgCollapsed] = useState(false);

  const [backgroundColor, setBackgroundColor] = useState("#ffffff");
  const [uploadedImage, setUploadedImage] = useState(null);

  // When user changes the background tab, remove the old background type
  useEffect(() => {
    switch (activeTab) {
      case "color":
        // Remove any existing stock/upload image
        setUploadedImage(null);
        break;
      case "stock":
      case "upload":
        // Reset color to white if the user picks stock or upload
        setBackgroundColor("#ffffff");
        break;
      default:
        break;
    }
  }, [activeTab]);

  // ======================
  // STICKER STATE
  // ======================
  const [imageCategories, setImageCategories] = useState({});
  const [selectedCategory, setSelectedCategory] = useState("jupiter");

  // ======================
  // KONVA/CANVAS STATE
  // ======================
  const [items, setItems] = useState([]);
  const [selectedId, setSelectedId] = useState(null);

  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  const imageContainerRef = useRef(null);
  const stageRef = useRef(null);

  // ======================
  // TEXT MODAL
  // ======================
  const [isTextModalOpen, setIsTextModalOpen] = useState(false);
  const [textInput, setTextInput] = useState("");
  const [textColor, setTextColor] = useState("#000000");
  const [fontSize, setFontSize] = useState(20);
  const [fontStyle, setFontStyle] = useState("normal");
  const [fontFamily, setFontFamily] = useState("Arial");

  // ======================
  // DROPZONE (Upload BG)
  // ======================
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      setUploadedImage(e.target.result);
      // auto-collapse the background panel
      setBgCollapsed(true);
    };
    reader.readAsDataURL(file);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  // ======================
  // FETCH IMAGES
  // ======================
  useEffect(() => {
    fetch("/dankifyImages/images.json")
      .then((res) => res.json())
      .then((data) => setImageCategories(data))
      .catch((err) => console.error("Could not load images.json:", err));
  }, []);

  // ======================
  // STAGE DIMENSIONS
  // ======================
  useEffect(() => {
    if (imageContainerRef.current) {
      const { offsetWidth, offsetHeight } = imageContainerRef.current;
      setImageDimensions({
        width: Math.min(offsetWidth, 500),
        height: offsetHeight,
      });
    }
    const handleKeyDown = (e) => {
      if (e.key === "Delete") handleDeleteSelected();
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  // ================
  // KONVA HELPERS
  // ================
  const handleTransform = (newAttrs) => {
    const { id, ...rest } = newAttrs;
    setItems((prev) =>
      prev.map((item) => (item.id === id ? { ...item, ...rest } : item))
    );
  };

  const addImageToCanvas = (src) => {
    const id = items.length ? items[items.length - 1].id + 1 : 1;
    setItems([
      ...items,
      {
        id,
        type: "image",
        src,
        x: 50,
        y: 50,
        width: 100,
        height: 100,
        rotation: 0,
        draggable: true,
      },
    ]);
  };

  const handleStageMouseDown = (e) => {
    const clickedOnEmpty = e.target === e.target.getStage();
    const clickedOnTransformer =
      e.target.getParent() && e.target.getParent().className === "Transformer";
    if (clickedOnEmpty || !clickedOnTransformer) {
      setSelectedId(null);
    }
  };

  // ===============
  // TEXT MODAL
  // ===============
  const openTextModal = () => setIsTextModalOpen(true);
  const closeTextModal = () => {
    setIsTextModalOpen(false);
    setTextInput("");
    setTextColor("#000000");
    setFontSize(10);
    setFontStyle("normal");
    setFontFamily("Arial");
  };
  const addTextToCanvas = () => {
    const id = items.length ? items[items.length - 1].id + 1 : 1;
    setItems([
      ...items,
      {
        id,
        type: "text",
        x: 50,
        y: 50,
        text: textInput,
        fontSize,
        fontStyle,
        fontFamily,
        fill: textColor,
        width: 200,
        draggable: true,
      },
    ]);
    closeTextModal();
  };

  // ===============
  // CLEAR & DELETE
  // ===============
  const clearCanvasItems = () => {
    setItems([]);
    setUploadedImage(null);
  };
  const handleDeleteSelected = useCallback(() => {
    if (selectedId) {
      setItems((prev) => prev.filter((item) => item.id !== selectedId));
      setSelectedId(null);
    }
  }, [selectedId]);

  // ===============
  // COPY & SAVE
  // ===============
  const copyToClipboard = () => {
    const stage = stageRef.current.getStage();
    const canvas = document.createElement("canvas");
    canvas.width = stage.width();
    canvas.height = stage.height();
    const ctx = canvas.getContext("2d");

    // Fill background color
    ctx.fillStyle = backgroundColor;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    const stageDataURL = stage.toDataURL();
    const img = new Image();
    img.src = stageDataURL;
    img.onload = () => {
      ctx.drawImage(img, 0, 0);
      canvas.toBlob((blob) => {
        if (navigator.clipboard && navigator.clipboard.write) {
          const item = new ClipboardItem({ "image/png": blob });
          navigator.clipboard
            .write([item])
            .then(() => toast.success("Dank Meme copied 👌"))
            .catch(() =>
              toast.error("Failed to copy. Try using the Save button.")
            );
        } else {
          toast.error(
            "Clipboard API not supported. Try using the Save button."
          );
        }
      });
    };
  };

  const saveImage = () => {
    const stage = stageRef.current.getStage();
    stage.toDataURL({
      mimeType: "image/png",
      callback: (dataUrl) => {
        const link = document.createElement("a");
        link.download = "dank-meme.png";
        link.href = dataUrl;
        link.click();
        toast.success("Image saved 👌");
      },
    });
  };

  // ===============
  // BACKGROUND
  // ===============
  const handleColorPick = (color) => {
    setBackgroundColor(color);
    // auto-collapse after pick
    setBgCollapsed(true);
  };
  const handleStockSelect = (src) => {
    setUploadedImage(src);
    // auto-collapse
    setBgCollapsed(true);
  };

  // ===============
  // STICKERS
  // ===============
  const handleTouchEnd = (e) => {
    const src = e.target.getAttribute("data-drag-src");
    if (src) {
      addImageToCanvas(src);
      e.target.removeAttribute("data-drag-src");
    }
  };

  // ===============
  // RENDER
  // ===============
  return (
    <div className={styles.dankifyContainer}>
      <ToastContainer />

      {/* ===================================
          SECTION: Background (collapsible)
      =================================== */}
      <div className={styles.section}>
        <div className={styles.sectionHeader}>
          {/* Left-aligned heading */}
          <h2 className={styles.leftHeading}>Select Background</h2>
          {/* Collapsible Arrow */}
          <button
            className={styles.collapseButton}
            onClick={() => setBgCollapsed(!bgCollapsed)}
          >
            {bgCollapsed ? "▼" : "▲"}
          </button>
        </div>

        {!bgCollapsed && (
          <div className={styles.sectionBody}>
            {/* Tabs, centered horizontally */}
            <div className={styles.tabsContainer}>
              <button
                className={`${styles.tabButton} ${
                  activeTab === "color" ? styles.activeTab : ""
                }`}
                onClick={() => setActiveTab("color")}
              >
                Color
              </button>
              <button
                className={`${styles.tabButton} ${
                  activeTab === "stock" ? styles.activeTab : ""
                }`}
                onClick={() => setActiveTab("stock")}
              >
                Stock
              </button>
              <button
                className={`${styles.tabButton} ${
                  activeTab === "upload" ? styles.activeTab : ""
                }`}
                onClick={() => setActiveTab("upload")}
              >
                Upload
              </button>
            </div>

            <div className={styles.tabContent}>
              {/* The tab content is also centered by .tabPanel aligning items center */}
              {activeTab === "color" && (
                <div className={styles.tabPanel}>
                  <HexColorPicker
                    color={backgroundColor}
                    onChange={handleColorPick}
                  />
                </div>
              )}
              {activeTab === "stock" && (
                <div className={styles.tabPanel}>
                  {imageCategories.stock && (
                    <div className={styles.stockImageList}>
                      {imageCategories.stock.map((src, idx) => (
                        <img
                          key={idx}
                          src={src}
                          alt={`stock-${idx}`}
                          className={styles.thumbnail}
                          onClick={() => handleStockSelect(src)}
                        />
                      ))}
                    </div>
                  )}
                </div>
              )}
              {activeTab === "upload" && (
                <div className={styles.tabPanel}>
                  <div className={styles.uploadContainer} {...getRootProps()}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <p>Drop files here...</p>
                    ) : (
                      <p>Drag 'n' drop or click to select</p>
                    )}
                    <button className={styles.uploadButton}>Choose File</button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      {/* ===================================
          SECTION: Stickers (not collapsible)
      =================================== */}
      <div className={styles.section}>
        <div className={styles.sectionHeader}>
          {/* Left heading, plus dropdown on same line (right) */}
          <h2 className={styles.leftHeading}>Stickers</h2>
          <select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            className={styles.stickerSelect}
          >
            {Object.keys(imageCategories)
              .filter((cat) => cat !== "stock")
              .map((cat) => (
                <option key={cat} value={cat}>
                  {cat}
                </option>
              ))}
          </select>
        </div>

        <div className={styles.sectionBody}>
          <div className={styles.imageList}>
            {imageCategories[selectedCategory]?.map((src, index) => (
              <img
                key={index}
                src={src}
                alt={`sticker-${index}`}
                className={styles.thumbnail}
                onTouchEnd={handleTouchEnd}
                onClick={(e) => {
                  e.preventDefault();
                  addImageToCanvas(src);
                }}
              />
            ))}
          </div>
        </div>
      </div>

      {/* ===================================
          SECTION: Canvas (not collapsible)
      =================================== */}
      <div className={styles.section}>
        <div className={styles.sectionHeader}>
          {/* Left heading */}
          <h2 className={styles.leftHeading}>Canvas</h2>
        </div>

        <div className={styles.sectionBody}>
          {/* The canvas is centered horizontally via margin: 0 auto */}
          <div
            className={styles.imageContainer}
            ref={imageContainerRef}
            style={{ backgroundColor }}
          >
            <Stage
              width={imageDimensions.width}
              height={imageDimensions.height}
              onMouseDown={handleStageMouseDown}
              onTouchStart={handleStageMouseDown}
              ref={stageRef}
              onContextMenu={(e) => {
                e.evt.preventDefault();
                copyToClipboard();
              }}
            >
              <Layer>
                {uploadedImage && (
                  <URLImage
                    src={uploadedImage}
                    x={0}
                    y={0}
                    width={imageDimensions.width}
                    height={imageDimensions.height}
                  />
                )}
                {items.map((item) => {
                  if (item.type === "image") {
                    return (
                      <URLImage
                        key={item.id}
                        {...item}
                        isSelected={item.id === selectedId}
                        onSelect={() => setSelectedId(item.id)}
                        onChange={handleTransform}
                        onTouchStart={() => setSelectedId(item.id)}
                      />
                    );
                  } else if (item.type === "text") {
                    return (
                      <DraggableText
                        key={item.id}
                        {...item}
                        isSelected={item.id === selectedId}
                        onSelect={() => setSelectedId(item.id)}
                        onChange={handleTransform}
                        onTouchStart={() => setSelectedId(item.id)}
                      />
                    );
                  }
                  return null;
                })}
              </Layer>
            </Stage>

            {!uploadedImage && (
              <div className={styles.placeholder}>No image uploaded yet</div>
            )}
          </div>

          <div className={styles.actionBar}>
            <button className={styles.actionButton} onClick={openTextModal}>
              Text
            </button>
            <button className={styles.actionButton} onClick={copyToClipboard}>
              Copy <FaCopy />
            </button>
            <button className={styles.actionButton} onClick={saveImage}>
              Save
            </button>
            <button className={styles.actionButton} onClick={clearCanvasItems}>
              Clear
            </button>
            <button
              className={styles.actionButton}
              onClick={handleDeleteSelected}
            >
              Delete
            </button>
          </div>
        </div>
      </div>

      {/* ===================================
          TEXT MODAL
      =================================== */}
      {isTextModalOpen && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <h3>Enter Text</h3>
            <textarea
              value={textInput}
              onChange={(e) => setTextInput(e.target.value)}
              style={{ width: "100%", height: "100px" }}
            />
            <div className={styles.textOptions}>
              <label>
                Font Size:
                <input
                  type="number"
                  value={fontSize}
                  onChange={(e) => setFontSize(Number(e.target.value))}
                  style={{ width: "60px" }}
                />
                <select
                  value={fontSize}
                  onChange={(e) => setFontSize(Number(e.target.value))}
                >
                  {[10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map((val) => (
                    <option key={val} value={val}>
                      {val}
                    </option>
                  ))}
                </select>
              </label>
              <label>
                Font Style:
                <select
                  value={fontStyle}
                  onChange={(e) => setFontStyle(e.target.value)}
                >
                  <option value="normal">Normal</option>
                  <option value="bold">Bold</option>
                  <option value="italic">Italic</option>
                </select>
              </label>
              <label>
                Font Family:
                <select
                  value={fontFamily}
                  onChange={(e) => setFontFamily(e.target.value)}
                >
                  <option value="Arial">Arial</option>
                  <option value="Courier New">Courier New</option>
                  <option value="Georgia">Georgia</option>
                  <option value="Times New Roman">Times New Roman</option>
                  <option value="Verdana">Verdana</option>
                  <option value="Tahoma">Tahoma</option>
                  <option value="Trebuchet MS">Trebuchet MS</option>
                  <option value="Impact">Impact</option>
                  <option value="Comic Sans MS">Comic Sans MS</option>
                  <option value="Lucida Console">Lucida Console</option>
                  <option value="Palatino Linotype">Palatino Linotype</option>
                </select>
              </label>
              <label>
                Text Color:
                <HexColorPicker color={textColor} onChange={setTextColor} />
              </label>
            </div>
            <button className={styles.uploadButton} onClick={addTextToCanvas}>
              Add Text
            </button>
            <button className={styles.uploadButton} onClick={closeTextModal}>
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dankify;
