import React, { useState, useRef, useEffect, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import {
  FaExpand,
  FaCopy,
  FaDownload,
  FaPlus,
  FaInfoCircle,
  FaRedo,
  FaTimes,
  FaEyeDropper,
} from "react-icons/fa";
import * as tf from "@tensorflow/tfjs"; // NEW: TensorFlow.js
import * as bodyPix from "@tensorflow-models/body-pix"; // NEW: BodyPix model
import styles from "./PepeToPork.module.css";
import defaultImage from "../../images/porkImage.jpeg";

const PepeToPork = () => {
  const defaultSettings = {
    targetThreshold: 100,
    targetDifference: 30,
    saturation: 100,
    brightness: 100,
    contrast: 100,
    targetColor: "green",
    minBrightness: 0,
    maxBrightness: 220,
    darkPixelFactor: 50,
  };

  const defaultTargetColorSettings = {
    targetColor: defaultSettings.targetColor,
    conversionColor: "#fea7dd",
    targetThreshold: defaultSettings.targetThreshold,
    targetDifference: defaultSettings.targetDifference,
  };

  const presets = [
    {
      name: "straightUpPork",
      label: "Straight Up Pork",
      settings: {
        targetColors: [defaultTargetColorSettings],
        saturation: 100,
        brightness: 100,
        contrast: 100,
        minBrightness: 0,
        maxBrightness: 220,
        darkPixelFactor: 50,
      },
    },
    {
      name: "baconBlast",
      label: "Bacon Blast",
      settings: {
        targetColors: [defaultTargetColorSettings],
        saturation: 150,
        brightness: 110,
        contrast: 120,
        minBrightness: 0,
        maxBrightness: 220,
        darkPixelFactor: 30,
      },
    },
    {
      name: "hamHarmony",
      label: "Ham Harmony",
      settings: {
        targetColors: [defaultTargetColorSettings],
        saturation: 70,
        brightness: 90,
        contrast: 80,
        minBrightness: 0,
        maxBrightness: 200,
        darkPixelFactor: 60,
      },
    },
    {
      name: "swineShine",
      label: "Swine Shine",
      settings: {
        targetColors: [defaultTargetColorSettings],
        saturation: 0,
        brightness: 100,
        contrast: 100,
        minBrightness: 0,
        maxBrightness: 220,
        darkPixelFactor: 50,
      },
    },
  ];

  const [originalImage, setOriginalImage] = useState(defaultImage);
  const [processedImage, setProcessedImage] = useState(null);
  const [isOriginalEnlarged, setIsOriginalEnlarged] = useState(false);
  const [isProcessedEnlarged, setIsProcessedEnlarged] = useState(false);
  const [targetColors, setTargetColors] = useState([
    defaultTargetColorSettings,
  ]);
  const [saturation, setSaturation] = useState(defaultSettings.saturation);
  const [brightness, setBrightness] = useState(defaultSettings.brightness);
  const [contrast, setContrast] = useState(defaultSettings.contrast);
  const [minBrightness, setMinBrightness] = useState(
    defaultSettings.minBrightness
  );
  const [maxBrightness, setMaxBrightness] = useState(
    defaultSettings.maxBrightness
  );
  const [darkPixelFactor, setDarkPixelFactor] = useState(
    defaultSettings.darkPixelFactor
  );
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedPreset, setSelectedPreset] = useState("straightUpPork");
  const canvasRef = useRef(null);
  const copyCanvasRef = useRef(null);

  // Tooltip visibility state and timers
  const [tooltipVisibility, setTooltipVisibility] = useState({});
  const tooltipTimers = useRef({});

  const [isPickingColor, setIsPickingColor] = useState(false);

  // NEW: State to indicate background removal in progress
  const [isRemovingBg, setIsRemovingBg] = useState(false);

  // Dropzone
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      setOriginalImage(e.target.result);
    };
    reader.readAsDataURL(file);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  // Effects
  useEffect(() => {
    if (originalImage) {
      convertColorToTarget();
    }
  }, [
    targetColors,
    originalImage,
    minBrightness,
    maxBrightness,
    darkPixelFactor,
  ]);

  useEffect(() => {
    applyFilters();
  }, [saturation, brightness, contrast]);

  // Presets
  const handlePresetChange = (presetName) => {
    setSelectedPreset(presetName);
    const preset = presets.find((p) => p.name === presetName);
    if (preset) {
      const {
        targetColors,
        saturation,
        brightness,
        contrast,
        minBrightness,
        maxBrightness,
        darkPixelFactor,
      } = preset.settings;

      setTargetColors(targetColors);
      setSaturation(saturation);
      setBrightness(brightness);
      setContrast(contrast);
      setMinBrightness(minBrightness);
      setMaxBrightness(maxBrightness);
      setDarkPixelFactor(darkPixelFactor);

      applyFilters();
      convertColorToTarget();
    }
  };

  // Convert color function
  const convertColorToTarget = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const image = new Image();
    image.crossOrigin = "Anonymous";
    image.src = originalImage;
    image.onload = () => {
      processImage(canvas, ctx, image);
    };
  };

  // Hex <-> RGB
  const hexToRgb = (hex) => {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return { r, g, b };
  };
  const rgbToHex = (r, g, b) => {
    const toHex = (c) => {
      const hex = c.toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    };
    return "#" + toHex(r) + toHex(g) + toHex(b);
  };

  // RGB <-> HSL
  const rgbToHsl = (r, g, b) => {
    r /= 255;
    g /= 255;
    b /= 255;
    const max = Math.max(r, g, b),
      min = Math.min(r, g, b);
    let h,
      s,
      l = (max + min) / 2;

    if (max === min) {
      h = s = 0; // achromatic
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = ((g - b) / d + (g < b ? 6 : 0)) % 6;
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
        default:
          break;
      }
      h /= 6;
    }
    return { h, s, l };
  };
  const hslToRgb = (h, s, l) => {
    let r, g, b;
    if (s === 0) {
      r = g = b = l * 255; // achromatic
    } else {
      const hue2rgb = (p, q, t) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 0.5) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      };
      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = hue2rgb(p, q, h + 1 / 3) * 255;
      g = hue2rgb(p, q, h) * 255;
      b = hue2rgb(p, q, h - 1 / 3) * 255;
    }
    return { r, g, b };
  };

  // Main color processing
  const processImage = (canvas, ctx, image) => {
    setIsProcessing(true);
    setErrorMessage("");
    try {
      canvas.width = image.width;
      canvas.height = image.height;
      ctx.drawImage(image, 0, 0);
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;

      const clusters = findColorClusters(data, canvas.width, canvas.height);
      clusters.forEach((cluster) => {
        const [dr, dg, db] = getDominantColor(cluster, data);
        const dominantHSL = rgbToHsl(dr, dg, db);

        cluster.forEach((index) => {
          const r = data[index];
          const g = data[index + 1];
          const b = data[index + 2];
          const brightnessValue = 0.299 * r + 0.587 * g + 0.114 * b;

          let matchedColorSetting = null;
          for (const colorSetting of targetColors) {
            if (matchesTargetColor(r, g, b, colorSetting)) {
              matchedColorSetting = colorSetting;
              break;
            }
          }

          if (matchedColorSetting) {
            const { conversionColor } = matchedColorSetting;
            const targetRGB = hexToRgb(conversionColor);
            const targetHSL = rgbToHsl(targetRGB.r, targetRGB.g, targetRGB.b);

            // Convert pixel color to HSL
            const pixelHSL = rgbToHsl(r, g, b);

            // Compute differences in S and L
            const deltaS = pixelHSL.s - dominantHSL.s;
            const deltaL = pixelHSL.l - dominantHSL.l;

            // Apply differences to target HSL
            let newS = targetHSL.s + deltaS;
            let newL = targetHSL.l + deltaL;

            // For dark pixels, reduce the amount of change
            if (brightnessValue < darkPixelFactor) {
              const factor = brightnessValue / darkPixelFactor; // Value between 0 and 1
              newS = newS * factor + pixelHSL.s * (1 - factor);
              newL = newL * factor + pixelHSL.l * (1 - factor);
            }

            // Clamp newS and newL to [0,1]
            newS = Math.min(Math.max(newS, 0), 1);
            newL = Math.min(Math.max(newL, 0), 1);

            // Use target hue
            const newH = targetHSL.h;

            // Convert back to RGB
            const newRGB = hslToRgb(newH, newS, newL);

            // Set pixel data
            data[index] = newRGB.r;
            data[index + 1] = newRGB.g;
            data[index + 2] = newRGB.b;
          }
        });
      });

      ctx.putImageData(imageData, 0, 0);
      setProcessedImage(canvas.toDataURL());
    } catch (error) {
      console.error("Image processing error:", error);
      setErrorMessage("An error occurred while processing the image.");
    }
    setIsProcessing(false);
  };

  const getDominantColor = (cluster, data) => {
    let totalR = 0,
      totalG = 0,
      totalB = 0;
    cluster.forEach((index) => {
      totalR += data[index];
      totalG += data[index + 1];
      totalB += data[index + 2];
    });
    const count = cluster.length;
    const avgR = totalR / count;
    const avgG = totalG / count;
    const avgB = totalB / count;
    return [avgR, avgG, avgB];
  };

  const findColorClusters = (data, width, height) => {
    const clusters = [];
    const visited = new Set();
    const getColorIndex = (x, y) => (y * width + x) * 4;
    const isValidPixel = (x, y) => {
      if (x < 0 || y < 0 || x >= width || y >= height) return false;
      const index = getColorIndex(x, y);
      const r = data[index];
      const g = data[index + 1];
      const b = data[index + 2];
      return isTargetColor(r, g, b);
    };
    const dfs = (x, y, cluster) => {
      const stack = [[x, y]];
      while (stack.length) {
        const [cx, cy] = stack.pop();
        const index = getColorIndex(cx, cy);
        if (!visited.has(index)) {
          visited.add(index);
          cluster.push(index);
          const neighbors = [
            [cx + 1, cy],
            [cx - 1, cy],
            [cx, cy + 1],
            [cx, cy - 1],
          ];
          for (const [nx, ny] of neighbors) {
            if (isValidPixel(nx, ny) && !visited.has(getColorIndex(nx, ny))) {
              stack.push([nx, ny]);
            }
          }
        }
      }
    };
    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        const index = getColorIndex(x, y);
        const r = data[index];
        const g = data[index + 1];
        const b = data[index + 2];
        if (isTargetColor(r, g, b) && !visited.has(index)) {
          const cluster = [];
          dfs(x, y, cluster);
          clusters.push(cluster);
        }
      }
    }
    return clusters;
  };

  const isTargetColor = (r, g, b) => {
    const brightnessValue = 0.299 * r + 0.587 * g + 0.114 * b;
    if (brightnessValue < minBrightness) return false;
    if (brightnessValue > maxBrightness) return false;

    return targetColors.some((colorSetting) =>
      matchesTargetColor(r, g, b, colorSetting)
    );
  };

  const matchesTargetColor = (r, g, b, colorSetting) => {
    const { targetColor, targetDifference, targetThreshold, isCustomColor } =
      colorSetting;

    if (isCustomColor) {
      // Custom color matching using Euclidean distance
      const deltaR = r - colorSetting.targetColor.r;
      const deltaG = g - colorSetting.targetColor.g;
      const deltaB = b - colorSetting.targetColor.b;

      const distance = Math.sqrt(
        deltaR * deltaR + deltaG * deltaG + deltaB * deltaB
      );

      const maxDistance = Math.sqrt(255 * 255 * 3); // Max possible distance
      const threshold = (targetDifference / 100) * maxDistance;

      return distance <= threshold;
    } else {
      // Existing code for predefined colors
      if (targetColor === "green") {
        return (
          g > r * (1 + targetDifference / 100) &&
          g > b * (1 + targetDifference / 100) &&
          g > targetThreshold
        );
      } else if (targetColor === "red") {
        return (
          r > g * (1 + targetDifference / 100) &&
          r > b * (1 + targetDifference / 100) &&
          r > targetThreshold
        );
      } else if (targetColor === "blue") {
        return (
          b > r * (1 + targetDifference / 100) &&
          b > g * (1 + targetDifference / 100) &&
          b > targetThreshold
        );
      }
    }
  };

  // Post-processing filters
  const applyFilters = () => {
    const imgElement = document.getElementById("processedImage");
    if (imgElement) {
      let filters = "";
      filters += `brightness(${brightness}%) `;
      filters += `saturate(${saturation}%) `;
      filters += `contrast(${contrast}%) `;
      imgElement.style.filter = filters.trim();
    }
  };

  // Toggle enlarge
  const toggleProcessedSize = () => {
    setIsProcessedEnlarged(!isProcessedEnlarged);
  };
  const toggleOriginalSize = () => {
    setIsOriginalEnlarged(!isOriginalEnlarged);
  };

  // Reset
  const resetToDefaultSettings = () => {
    handlePresetChange("straightUpPork");
  };

  // Modal close
  const handleClickOutside = (e) => {
    if (isOriginalEnlarged || isProcessedEnlarged) {
      if (e.target.classList.contains(styles.modalOverlay)) {
        setIsOriginalEnlarged(false);
        setIsProcessedEnlarged(false);
      }
    }
    setTooltipVisibility({});
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isOriginalEnlarged, isProcessedEnlarged]);

  // Copy / Download
  const handleCopyImage = (imageSrc) => {
    const canvas = copyCanvasRef.current;
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.src = imageSrc;
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;

      ctx.filter = `brightness(${brightness}%) saturate(${saturation}%) contrast(${contrast}%)`;
      ctx.drawImage(img, 0, 0);

      canvas.toBlob((blob) => {
        const item = new ClipboardItem({ "image/png": blob });
        navigator.clipboard
          .write([item])
          .then(() => {
            alert("Image copied to clipboard!");
          })
          .catch((err) => {
            console.error("Failed to copy image: ", err);
          });
      });
    };
  };
  const handleDownloadImage = (imageSrc) => {
    const canvas = copyCanvasRef.current;
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.src = imageSrc;
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;

      ctx.filter = `brightness(${brightness}%) saturate(${saturation}%) contrast(${contrast}%)`;
      ctx.drawImage(img, 0, 0);

      canvas.toBlob((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "image.png";
        link.click();
      });
    };
  };

  // Target color manipulation
  const addTargetColor = () => {
    setTargetColors([
      ...targetColors,
      {
        targetColor: defaultSettings.targetColor,
        conversionColor: "#fea7dd",
        targetThreshold: defaultSettings.targetThreshold,
        targetDifference: defaultSettings.targetDifference,
      },
    ]);
  };
  const startPickingColor = async () => {
    if ("EyeDropper" in window) {
      setIsPickingColor(true);
      try {
        const eyeDropper = new window.EyeDropper();
        const result = await eyeDropper.open();
        const selectedColor = hexToRgb(result.sRGBHex);
        addCustomTargetColor(selectedColor);
      } catch (e) {
        console.error(e);
      } finally {
        setIsPickingColor(false);
      }
    } else {
      alert("Your browser does not support the EyeDropper API.");
    }
  };
  const addCustomTargetColor = (selectedColor) => {
    setTargetColors([
      ...targetColors,
      {
        isCustomColor: true,
        targetColor: selectedColor,
        conversionColor: "#fea7dd",
        targetDifference: defaultSettings.targetDifference,
      },
    ]);
  };
  const handleSwatchClick = async () => {
    await startPickingColor();
  };
  const removeTargetColor = (index) => {
    const newTargetColors = [...targetColors];
    newTargetColors.splice(index, 1);
    setTargetColors(newTargetColors);
  };
  const updateTargetColor = (index, value) => {
    const newTargetColors = [...targetColors];
    newTargetColors[index].targetColor = value;
    setTargetColors(newTargetColors);
  };
  const updateConversionColor = (index, value) => {
    const newTargetColors = [...targetColors];
    newTargetColors[index].conversionColor = value;
    setTargetColors(newTargetColors);
  };
  const updateTargetThreshold = (index, value) => {
    const newTargetColors = [...targetColors];
    newTargetColors[index].targetThreshold = value;
    setTargetColors(newTargetColors);
  };
  const updateTargetDifference = (index, value) => {
    const newTargetColors = [...targetColors];
    newTargetColors[index].targetDifference = value;
    setTargetColors(newTargetColors);
  };

  // Update handlers for sliders
  const handleSaturationChange = (value) => {
    setSaturation(value);
    applyFilters();
  };
  const handleBrightnessChange = (value) => {
    setBrightness(value);
    applyFilters();
  };
  const handleContrastChange = (value) => {
    setContrast(value);
    applyFilters();
  };
  const handleMinBrightnessChange = (value) => {
    setMinBrightness(value);
    convertColorToTarget();
  };
  const handleMaxBrightnessChange = (value) => {
    setMaxBrightness(value);
    convertColorToTarget();
  };
  const handleDarkPixelFactorChange = (value) => {
    setDarkPixelFactor(value);
    convertColorToTarget();
  };

  // NEW: Offline background removal using BodyPix
  const removeBackground = async () => {
    if (!processedImage) {
      alert(
        "Please upload or process an image before removing the background."
      );
      return;
    }
    setIsRemovingBg(true);
    try {
      // 1. Load the BodyPix model
      const net = await bodyPix.load({
        architecture: "ResNet50",
        outputStride: 32, // can be 8, 16, or 32
        quantBytes: 2,
      });


      // 2. Draw the processed image onto the main canvas for segmentation
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.src = processedImage;

      img.onload = async () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);

      // 3. Generate segmentation mask (1 = person, 0 = background)
      const segmentation = await net.segmentPerson(canvas, {
        // Try higher internalResolution to capture more detail
        internalResolution: "medium", // "low" | "medium" | "high" | "full"

        // segmentationThreshold controls how strict the model is
        //   → higher = more strict, might remove parts of the subject
        //   → lower = more forgiving, might include background
        segmentationThreshold: 0.1, // Range: [0.0, 1.0]

        // If you only expect one person, limit detection
        maxDetections: 1,

        // Minimum confidence to accept a detection
        scoreThreshold: 0.4, // Range: [0.0, 1.0]

        // Non-Maximum Suppression radius (for multi-person)
        nmsRadius: 20,
      });

      // Extract the raw mask array
      const { data: mask } = segmentation;

      // 4. Make background pixels transparent
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const pixelData = imageData.data;

      for (let i = 0; i < mask.length; i++) {
        // If mask[i] = 0 => background => set alpha to 0
        if (mask[i] === 0) {
          pixelData[i * 4 + 3] = 0; // alpha channel
        }
      }

        ctx.putImageData(imageData, 0, 0);

        // 5. Update processedImage with the new (transparent) background
        const newUrl = canvas.toDataURL("image/png");
        setProcessedImage(newUrl);
        setIsRemovingBg(false);
      };
    } catch (error) {
      console.error("Background removal error:", error);
      setIsRemovingBg(false);
    }
  };

  // Tooltip functions
  const showTooltip = (key, event) => {
    // (Optional) only show on desktop
    if (window.innerWidth <= 750) return;

    // clear any hide timer so it won't instantly disappear
    clearTimeout(tooltipTimers.current[key]);

    // show the tooltip for this 'key'
    setTooltipVisibility({ ...tooltipVisibility, [key]: true });
  };

  const hideTooltip = (key) => {
    // fade out after half a second
    tooltipTimers.current[key] = setTimeout(() => {
      setTooltipVisibility((prev) => ({ ...prev, [key]: false }));
    }, 500);
  };

  const handleTooltipClick = (key) => {
    // (Optional) only trigger tooltips by click on mobile
    if (window.innerWidth > 750) return;

    // toggle tooltip
    setTooltipVisibility({
      ...tooltipVisibility,
      [key]: !tooltipVisibility[key],
    });

    // auto-hide after 4 seconds if we just showed it
    if (!tooltipVisibility[key]) {
      tooltipTimers.current[key] = setTimeout(() => {
        setTooltipVisibility((prev) => ({ ...prev, [key]: false }));
      }, 4000);
    } else {
      clearTimeout(tooltipTimers.current[key]);
    }
  };

  return (
    <div className={styles.pepeToPink}>
      <h2>PEPE to PORK</h2>

      {/* Upload Container */}
      <div className={styles.uploadContainer} {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <p>Drag 'n' drop an image here, or click to select an image</p>
        )}
        <button className={styles.uploadButton}>PORK IT!</button>
      </div>

      {errorMessage && (
        <div className={styles.errorMessage}>{errorMessage}</div>
      )}
      {isProcessing && (
        <div className={styles.loadingContainer}>
          <p>Processing...</p>
        </div>
      )}

      {/* Settings Container */}
      <div className={styles.settingsContainer}>
        <h3>Settings</h3>
        <button
          onClick={resetToDefaultSettings}
          className={styles.resetButton}
          title="Reset to Default Settings"
        >
          <FaRedo />
        </button>

        <div className={styles.slidersContainer}>
          {/* Preset Selection */}
          <div className={styles.sliderGroup}>
            <div className={styles.presetContainer}>
              <label className={styles.presetLabel}>Presets:</label>
              <div className={styles.presetOptions}>
                {presets.map((preset) => (
                  <label key={preset.name} className={styles.presetOption}>
                    <input
                      type="radio"
                      name="preset"
                      value={preset.name}
                      checked={selectedPreset === preset.name}
                      onChange={() => handlePresetChange(preset.name)}
                    />
                    {preset.label}
                  </label>
                ))}
              </div>
            </div>
          </div>

          {/* First Row */}
          <div className={styles.sliderGroup}>
            <div className={styles.sliderContainer}>
              <div className={styles.labelContainer}>
                <label>Saturation: {saturation}%</label>
                <FaInfoCircle
                  className={styles.infoIcon}
                  onMouseEnter={(e) => showTooltip("saturation", e)}
                  onMouseLeave={() => hideTooltip("saturation")}
                  onClick={() => handleTooltipClick("saturation")}
                />
                {tooltipVisibility.saturation && (
                  <div className={styles.tooltip}>
                    Adjusts the intensity of colors in the image.
                  </div>
                )}
              </div>
              <input
                type="range"
                min="0"
                max="200"
                value={saturation}
                onChange={(e) =>
                  handleSaturationChange(parseInt(e.target.value, 10))
                }
                className={styles.slider}
              />
            </div>

            <div className={styles.sliderContainer}>
              <div className={styles.labelContainer}>
                <label>Brightness: {brightness}%</label>
                <FaInfoCircle
                  className={styles.infoIcon}
                  onMouseEnter={(e) => showTooltip("brightness", e)}
                  onMouseLeave={() => hideTooltip("brightness")}
                  onClick={() => handleTooltipClick("brightness")}
                />
                {tooltipVisibility.brightness && (
                  <div className={styles.tooltip}>
                    Adjusts the overall lightness or darkness of the image.
                  </div>
                )}
              </div>
              <input
                type="range"
                min="0"
                max="200"
                value={brightness}
                onChange={(e) =>
                  handleBrightnessChange(parseInt(e.target.value, 10))
                }
                className={styles.slider}
              />
            </div>
          </div>

          {/* Second Row */}
          <div className={styles.sliderGroup}>
            <div className={styles.sliderContainer}>
              <div className={styles.labelContainer}>
                <label>Contrast: {contrast}%</label>
                <FaInfoCircle
                  className={styles.infoIcon}
                  onMouseEnter={(e) => showTooltip("contrast", e)}
                  onMouseLeave={() => hideTooltip("contrast")}
                  onClick={() => handleTooltipClick("contrast")}
                />
                {tooltipVisibility.contrast && (
                  <div className={styles.tooltip}>
                    Adjusts the difference between light and dark areas.
                  </div>
                )}
              </div>
              <input
                type="range"
                min="0"
                max="200"
                value={contrast}
                onChange={(e) =>
                  handleContrastChange(parseInt(e.target.value, 10))
                }
                className={styles.slider}
              />
            </div>

            <div className={styles.sliderContainer}>
              <div className={styles.labelContainer}>
                <label>Dark Pixel Factor: {darkPixelFactor}</label>
                <FaInfoCircle
                  className={styles.infoIcon}
                  onMouseEnter={(e) => showTooltip("darkPixelFactor", e)}
                  onMouseLeave={() => hideTooltip("darkPixelFactor")}
                  onClick={() => handleTooltipClick("darkPixelFactor")}
                />
                {tooltipVisibility.darkPixelFactor && (
                  <div className={styles.tooltip}>
                    Controls how much color change is applied to darker pixels.
                  </div>
                )}
              </div>
              <input
                type="range"
                min="1"
                max="100"
                value={darkPixelFactor}
                onChange={(e) =>
                  handleDarkPixelFactorChange(parseInt(e.target.value, 10))
                }
                className={styles.slider}
              />
            </div>
          </div>

          {/* Third Row */}
          <div className={styles.sliderGroup}>
            <div className={styles.sliderContainer}>
              <div className={styles.labelContainer}>
                <label>Min Brightness: {minBrightness}</label>
                <FaInfoCircle
                  className={styles.infoIcon}
                  onMouseEnter={(e) => showTooltip("minBrightness", e)}
                  onMouseLeave={() => hideTooltip("minBrightness")}
                  onClick={() => handleTooltipClick("minBrightness")}
                />
                {tooltipVisibility.minBrightness && (
                  <div className={styles.tooltip}>
                    Pixels darker than this value are ignored in processing.
                  </div>
                )}
              </div>
              <input
                type="range"
                min="0"
                max="100"
                value={minBrightness}
                onChange={(e) =>
                  handleMinBrightnessChange(parseInt(e.target.value, 10))
                }
                className={styles.slider}
              />
            </div>

            <div className={styles.sliderContainer}>
              <div className={styles.labelContainer}>
                <label>Max Brightness: {maxBrightness}</label>
                <FaInfoCircle
                  className={styles.infoIcon}
                  onMouseEnter={(e) => showTooltip("maxBrightness", e)}
                  onMouseLeave={() => hideTooltip("maxBrightness")}
                  onClick={() => handleTooltipClick("maxBrightness")}
                />
                {tooltipVisibility.maxBrightness && (
                  <div className={styles.tooltip}>
                    Pixels brighter than this value are ignored in processing.
                  </div>
                )}
              </div>
              <input
                type="range"
                min="150"
                max="255"
                value={maxBrightness}
                onChange={(e) =>
                  handleMaxBrightnessChange(parseInt(e.target.value, 10))
                }
                className={styles.slider}
              />
            </div>
          </div>
        </div>

        {/* NEW: Remove Background Button */}
        <div className={styles.sliderGroup} style={{ marginTop: "15px" }}>
          <button onClick={removeBackground} disabled={isRemovingBg}>
            {isRemovingBg ? "Removing BG..." : "Remove Background"}
          </button>
        </div>
      </div>

      {/* Image Display */}
      <div className={styles.imageContainer}>
        <div className={styles.imageBox}>
          <h3>Original</h3>
          <div className={styles.iconContainer}>
            <button
              onClick={toggleOriginalSize}
              className={styles.iconButton}
              title="Enlarge Image"
            >
              <FaExpand />
            </button>
            {originalImage && (
              <>
                <button
                  onClick={() => handleCopyImage(originalImage)}
                  className={styles.iconButton}
                  title="Copy Image"
                >
                  <FaCopy />
                </button>
                <button
                  onClick={() => handleDownloadImage(originalImage)}
                  className={styles.iconButton}
                  title="Download Image"
                >
                  <FaDownload />
                </button>
              </>
            )}
          </div>
          <div className={styles.imageWrapper}>
            <img
              src={originalImage || ""}
              alt="Original"
              className={styles.image}
              style={{
                display: originalImage ? "block" : "none",
              }}
            />
          </div>
          {!originalImage && (
            <div className={styles.placeholder}>No Original Image</div>
          )}
        </div>

        <div className={styles.imageBox}>
          <h3>PORK'd</h3>
          <div className={styles.iconContainer}>
            <button
              onClick={toggleProcessedSize}
              className={styles.iconButton}
              title="Enlarge Image"
            >
              <FaExpand />
            </button>
            {processedImage && (
              <>
                <button
                  onClick={() => handleCopyImage(processedImage)}
                  className={styles.iconButton}
                  title="Copy Image"
                >
                  <FaCopy />
                </button>
                <button
                  onClick={() => handleDownloadImage(processedImage)}
                  className={styles.iconButton}
                  title="Download Image"
                >
                  <FaDownload />
                </button>
              </>
            )}
          </div>
          <img
            id="processedImage"
            src={processedImage || ""}
            alt="Processed"
            className={styles.image}
            style={{ display: processedImage ? "block" : "none" }}
          />
          {!processedImage && (
            <div className={styles.placeholder}>No Processed Image</div>
          )}
        </div>
      </div>

      {/* Modal for Enlarged Images */}
      {(isOriginalEnlarged || isProcessedEnlarged) && (
        <div className={styles.modalOverlay} onClick={handleClickOutside}>
          <div className={styles.modalContent}>
            <button
              className={styles.closeModalButton}
              onClick={() => {
                setIsOriginalEnlarged(false);
                setIsProcessedEnlarged(false);
              }}
            >
              <FaTimes />
            </button>
            <img
              src={
                isOriginalEnlarged ? originalImage || "" : processedImage || ""
              }
              alt="Enlarged"
              className={styles.enlargedImage}
            />
          </div>
        </div>
      )}

      {/* Target Colors */}
      <div className={styles.targetColorsContainer}>
        <div className={styles.controlIcons}>
          <button
            onClick={addTargetColor}
            className={styles.iconButton}
            title="Add Target Color"
          >
            <FaPlus />
          </button>
          <button
            onClick={startPickingColor}
            className={styles.iconButton}
            title="Add Custom Target Color"
          >
            <FaEyeDropper />
          </button>
        </div>
        {targetColors.map((colorSetting, index) => (
          <div key={index} className={styles.targetGroup}>
            <div className={styles.targetGroupHeader}>
              <button
                onClick={() => removeTargetColor(index)}
                className={styles.removeButton}
                title="Remove Target Color"
              >
                ×
              </button>
            </div>

            {colorSetting.isCustomColor ? (
              <>
                <div className={styles.targetRow}>
                  <div className={styles.formGroupFull}>
                    <div className={styles.labelContainer}>
                      <label>Selected Color:</label>
                      <FaInfoCircle
                        className={styles.infoIcon}
                        onMouseEnter={(e) =>
                          showTooltip(`selectedColor${index}`, e)
                        }
                        onMouseLeave={() =>
                          hideTooltip(`selectedColor${index}`)
                        }
                        onClick={() =>
                          handleTooltipClick(`selectedColor${index}`)
                        }
                      />
                      {tooltipVisibility[`selectedColor${index}`] && (
                        <div className={styles.tooltip}>
                          This is the color you selected from the image.
                        </div>
                      )}
                    </div>
                    <input
                      type="color"
                      value={rgbToHex(
                        colorSetting.targetColor.r,
                        colorSetting.targetColor.g,
                        colorSetting.targetColor.b
                      )}
                      onClick={handleSwatchClick}
                      readOnly
                      className={styles.colorInput}
                      style={{ marginTop: "5px" }}
                    />
                  </div>
                  <div className={styles.formGroupFull}>
                    <div className={styles.labelContainer}>
                      <label>Conversion Color:</label>
                      <FaInfoCircle
                        className={styles.infoIcon}
                        onMouseEnter={(e) =>
                          showTooltip(`conversionColor${index}`, e)
                        }
                        onMouseLeave={() =>
                          hideTooltip(`conversionColor${index}`)
                        }
                        onClick={() =>
                          handleTooltipClick(`conversionColor${index}`)
                        }
                      />
                      {tooltipVisibility[`conversionColor${index}`] && (
                        <div className={styles.tooltip}>
                          Choose the color you want the target color to change
                          to.
                        </div>
                      )}
                    </div>
                    <input
                      type="color"
                      value={colorSetting.conversionColor}
                      onChange={(e) =>
                        updateConversionColor(index, e.target.value)
                      }
                      className={styles.colorInput}
                      style={{ marginTop: "5px" }}
                    />
                  </div>
                </div>
                <div className={styles.spacer}></div>
                <div className={styles.targetRow}>
                  <div className={styles.formGroupFull}>
                    <div className={styles.labelContainer}>
                      <label>
                        Target Difference: {colorSetting.targetDifference}%
                      </label>
                      <FaInfoCircle
                        className={styles.infoIcon}
                        onMouseEnter={(e) =>
                          showTooltip(`targetDifference${index}`, e)
                        }
                        onMouseLeave={() =>
                          hideTooltip(`targetDifference${index}`)
                        }
                        onClick={() =>
                          handleTooltipClick(`targetDifference${index}`)
                        }
                      />
                      {tooltipVisibility[`targetDifference${index}`] && (
                        <div className={styles.tooltip}>
                          Controls how closely colors must match the selected
                          color to be replaced.
                        </div>
                      )}
                    </div>
                    <input
                      type="range"
                      min="0"
                      max="100"
                      value={colorSetting.targetDifference}
                      onChange={(e) =>
                        updateTargetDifference(
                          index,
                          parseInt(e.target.value, 10)
                        )
                      }
                      className={styles.slider}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className={styles.targetRow}>
                  <div className={styles.formGroupHalf}>
                    <div className={styles.labelContainer}>
                      <label>Target Color:</label>
                      <FaInfoCircle
                        className={styles.infoIcon}
                        onMouseEnter={(e) =>
                          showTooltip(`targetColor${index}`, e)
                        }
                        onMouseLeave={() => hideTooltip(`targetColor${index}`)}
                        onClick={() =>
                          handleTooltipClick(`targetColor${index}`)
                        }
                      />
                      {tooltipVisibility[`targetColor${index}`] && (
                        <div className={styles.tooltip}>
                          Select the color in the image you want to replace.
                        </div>
                      )}
                    </div>
                    <select
                      value={colorSetting.targetColor}
                      onChange={(e) => updateTargetColor(index, e.target.value)}
                      className={styles.select}
                      style={{ marginTop: "5px" }}
                    >
                      <option value="green">Green</option>
                      <option value="red">Red</option>
                      <option value="blue">Blue</option>
                    </select>
                  </div>
                  <div className={styles.formGroupHalf}>
                    <div className={styles.labelContainer}>
                      <label>Conversion Color:</label>
                      <FaInfoCircle
                        className={styles.infoIcon}
                        onMouseEnter={(e) =>
                          showTooltip(`conversionColor${index}`, e)
                        }
                        onMouseLeave={() =>
                          hideTooltip(`conversionColor${index}`)
                        }
                        onClick={() =>
                          handleTooltipClick(`conversionColor${index}`)
                        }
                      />
                      {tooltipVisibility[`conversionColor${index}`] && (
                        <div className={styles.tooltip}>
                          Choose the color you want the target color to change
                          to.
                        </div>
                      )}
                    </div>
                    <input
                      type="color"
                      value={colorSetting.conversionColor}
                      onChange={(e) =>
                        updateConversionColor(index, e.target.value)
                      }
                      className={styles.colorInput}
                      style={{ marginTop: "5px" }}
                    />
                  </div>
                </div>
                <div className={styles.spacer}></div>
                <div className={styles.targetRow}>
                  <div className={styles.formGroupHalf}>
                    <div className={styles.labelContainer}>
                      <label>
                        Target Threshold: {colorSetting.targetThreshold}
                      </label>
                      <FaInfoCircle
                        className={styles.infoIcon}
                        onMouseEnter={(e) =>
                          showTooltip(`targetThreshold${index}`, e)
                        }
                        onMouseLeave={() =>
                          hideTooltip(`targetThreshold${index}`)
                        }
                        onClick={() =>
                          handleTooltipClick(`targetThreshold${index}`)
                        }
                      />
                      {tooltipVisibility[`targetThreshold${index}`] && (
                        <div className={styles.tooltip}>
                          Minimum intensity for the target color to be replaced.
                        </div>
                      )}
                    </div>
                    <input
                      type="range"
                      min="0"
                      max="255"
                      value={colorSetting.targetThreshold}
                      onChange={(e) =>
                        updateTargetThreshold(
                          index,
                          parseInt(e.target.value, 10)
                        )
                      }
                      className={styles.slider}
                    />
                  </div>
                  <div className={styles.formGroupHalf}>
                    <div className={styles.labelContainer}>
                      <label>
                        Target Difference: {colorSetting.targetDifference}%
                      </label>
                      <FaInfoCircle
                        className={styles.infoIcon}
                        onMouseEnter={(e) =>
                          showTooltip(`targetDifference${index}`, e)
                        }
                        onMouseLeave={() =>
                          hideTooltip(`targetDifference${index}`)
                        }
                        onClick={() =>
                          handleTooltipClick(`targetDifference${index}`)
                        }
                      />
                      {tooltipVisibility[`targetDifference${index}`] && (
                        <div className={styles.tooltip}>
                          Controls how much the target color must differ from
                          others to be replaced.
                        </div>
                      )}
                    </div>
                    <input
                      type="range"
                      min="0"
                      max="100"
                      value={colorSetting.targetDifference}
                      onChange={(e) =>
                        updateTargetDifference(
                          index,
                          parseInt(e.target.value, 10)
                        )
                      }
                      className={styles.slider}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        ))}
      </div>

      <canvas ref={canvasRef} style={{ display: "none" }} />
      <canvas ref={copyCanvasRef} style={{ display: "none" }} />
    </div>
  );
};

export default PepeToPork;
